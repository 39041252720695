<template>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Mitsui SSOL ログイン</h4>
                    <p class=""><a href="https://mallento.com/wp-content/uploads/2020/04/株式会社マレント開発業務等受託約款-兼-同意書.pdf" target="_blank" style="color:#000080;">約款・利用規約</a>に同意してログインするものとします</p>
                </div>
                <div class="card-body">
                  <form role="form" @submit.prevent="signIn">
                    <div class="mb-3">
                      <div class="form-group">
                        <div>
                          <input
                            type="text"
                            class="form-control"
                            name="email"
                            id="email"
                            placeholder="メールアドレス"
                            v-model="email"
                          />
                        </div>
                      </div>
                      
                    </div>
                    <div class="mb-3">

                      <div class="form-group">
                        <div>
                          <input
                            type="password"
                            class="form-control"
                            name="password"
                            id="password"
                            placeholder="パスワード"
                            v-model="password"
                          />
                        </div>
                      </div>

                    </div>
                    <div id="error" class="text-sm text-danger" v-if="error">ログインできません。ログイン情報を再度確認お願いします。</div>
                    <!-- <argon-switch id="rememberMe">Remember me</argon-switch> -->

                    <div class="text-center">
                      <argon-button
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                      >ログインする</argon-button>
                    </div>
                  </form>
                </div>
                <!-- <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">
                    Don't have an account?
                    <a
                      href="javascript:;"
                      class="text-success text-gradient font-weight-bold"
                    >Sign up</a>
                  </p>
                </div> -->
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-ill.jpg');
                  background-size: cover;"
              >
                <!-- <span class="mask bg-gradient-success opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >"Attention is the new currency"</h4>
                <p
                  class="text-white position-relative"
                >The more effortless the writing looks, the more effort the writer actually put into the process.</p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { ref } from 'vue'
// import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
const body = document.getElementsByTagName("body")[0];

export default {
  name: "signin",
  data: () => {
    return {
    }
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const error = ref('')
    const email = ref('')
    const password = ref('')
    const signIn = async () => {
      try {
        const result = await store.dispatch('logIn', {
          email: email.value,
          password: password.value
        })
        if (!result) {
          error.value = true
        } else {
          router.push('/')
        }
      } catch (e) {
        console.error(e)
      }
      // this.logIn(this.email, this.password)
    }
    return {
      signIn, name, email, password, error
    }
  },
  components: {
    // ArgonSwitch,
    ArgonButton,
  },
  methods: {
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    body.classList.add("bg-gray-100");
  },
};
</script>
