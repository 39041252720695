import * as firebase from "firebase/app";
import {getAuth} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBNoLpyCb9MSBU6SelpRQgfoqlOh66cctI",
  authDomain: "mallento-lisence.firebaseapp.com",
  projectId: "mallento-lisence",
  storageBucket: "mallento-lisence.appspot.com",
  messagingSenderId: "884686280665",
  appId: "1:884686280665:web:13b37574bef908f10a2bc8"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

//initialize firebase auth
const auth = getAuth()
const db = getFirestore()
export { app, auth, db }
