<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
        <notifications-table />
      </div>
    </div>
  </div>
</template>

<script>
import NotificationsTable from "./components/NotificationsTable.vue";

export default {
  name: "tables",
  components: {
    NotificationsTable,
  },
  data() {
    return {
    };
  },
};
</script>
