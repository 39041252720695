<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
        <div class="card">
          <div class="card-body mt-2 pt-0 pb-2 form-wrapper">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <tbody>
                  <tr>
                    <th>
                      ドメインURL
                    </th>
                    <td>
                      {{domainObj.domain}}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      ライセンス状態
                    </th>
                    <td v-if="user.role === 'admin'">
                      <label class="form-control-label" for="status1">
                        <input class="form-control-radio" v-model="domainObj.status" id="status1" type="radio" name="status" value="1">
                        有効
                      </label>
                      <label for="status2">
                        <input type="radio" name="status" v-model="domainObj.status" value="0" id="status2">
                        無効
                      </label>
                    </td>
                    <td v-else>
                      {{ domainObj.status === '1' ? '有効' : '無効' }}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      申請日
                    </th>
                    <td>
                      <flat-pickr :config="config" v-if="user.role === 'admin'" class="form-control bg-white" v-model="domainObj.createdDate"/>
                      <template v-else>{{domainObj.createdDate}}</template>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      入金日
                    </th>
                    <td>
                      <flat-pickr :config="config" v-if="user.role === 'admin'" class="form-control bg-white" v-model="domainObj.payedDate"/>
                      <template v-else>
                        {{domainObj.payedDate}}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      ライセンス有効開始日
                    </th>
                    <td>
                      <flat-pickr :config="config" v-if="user.role === 'admin'" class="form-control bg-white" v-model="domainObj.startedDate"/>
                      <template v-else>{{ domainObj.startedDate }}</template>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      購入者
                    </th>
                    <td>
                      <select v-if="user.role === 'admin'" v-model="domainObj.userId" class="form-control">
                        <option v-for="user in users" :key="user.id" :value="user.id">
                          {{user.name}}
                        </option>
                      </select>
                      <template v-else>
                        {{userData}}
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      備考欄
                    </th>
                    <td>
                      <template v-if="user.role === 'admin'" >
                        <textarea class="form-control" name="" v-model="domainObj.comment" id="" cols="30" rows="10"></textarea>
                      </template>
                      <template v-else>
                        {{domainObj.comment}}
                      </template>
                    </td>
                  </tr>
                  
                  <tr>
                    <th>
                      ドキュメント
                    </th>
                    <td>
                      <a href="https://mallento.com/wp-content/uploads/2020/04/株式会社マレント開発業務等受託約款-兼-同意書.pdf">約款・利用規約</a>
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
              <div v-if="user.role === 'admin'">
                <div class="row">
                  <div class="col-4">
                    <button type="button" class="form-control btn btn-primary mt-3" @click="savedomain">保存する</button>
                  </div>
                  <div class="col-4">
                    <button type="button" class="form-control btn btn-danger mt-3" @click="deleteDomain">削除する</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, getDoc, setDoc } from '@firebase/firestore';
import { db, } from '../firebaseInit';
import Swal from 'sweetalert2';
import { computed, onMounted, ref } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Japanese} from 'flatpickr/dist/l10n/ja.js';
export default {
  name: "tables",
  data () {
    return {
      domainObj: {},
      id: this.$router.currentRoute.value.params?.id
    }
  },
  components: {
    flatPickr
  },
  setup () {
    const config = ref({
      wrap: true, // set wrap to true only when using 'input-group'
      altFormat: 'M j, Y',
      altInput: true,
      dateFormat: 'Y-m-d',
      locale: Japanese
    })
    const userData = ref('');
    const router = useRouter()
    const store = useStore()
    let domain = {};

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    const fetchDomain = async () => {
      const serversCollection = doc(db, "servers", router.currentRoute.value.params?.id);
      const cdomain = await getDoc(serversCollection)
      domain = cdomain.data()
      console.log(domain)
      return domain
    };

    const users = computed(() => {
      return store.getters.users.filter(user => user.role !== 'admin');
    })

    const user = computed(() => {
      return store.getters.user;
    })

    const fetchUser = async (id) => {
      if (id) {
        try {
          const userCollection = doc(db, "users", id);
          const user = await getDoc(userCollection);
          userData.value = user.data().name ?? '';
        } catch (error) {
          console.error('Error fetching user:', error);
        }
      }
    };

    // Fetch user data when the component is mounted
    onMounted(async () => {
    });

    return {
      userData, domain, fetchDomain, fetchUser, formatDate, users, user, config
    }
  },
  async mounted () {
      this.domainObj = await this.fetchDomain()
      await this.fetchUser(this.domainObj.userId);
  },
  methods: {
    async savedomain () {
      const serversCollection = doc(db, "servers", this.id);
      try {
        // const currentDate = new Date();
        // const timestamp = currentDate.getTime(); 
        await setDoc(serversCollection, {
          ...this.domainObj,
          // startedDate: this.domainObj.startedDate === '' && this.domainObj.status === '1' ? timestamp : '',
          // payedDate: this.domainObj.payedDate ?? ''
        })
        const self = this
        Swal.fire('', '成功しました。', 'success').then(function () {
          self.$router.push('/domains')
        })
      } catch (e) {
        console.error(e)
      }
    },

    async deleteDomain () {
      const self = this
      await Swal.fire({
        title: 'ドメインを削除しますか?',
        showDenyButton: false,
        showCancelButton: true,
      }).then(async function (e) {
        if (!e.isDismissed) {
          await self.$store.dispatch('deleteDomain', { key: self.id })
          await Swal.fire('', '削除しました。', 'success')
          self.$router.push('/domains')
        }
      })
    }
  }
};
</script>
