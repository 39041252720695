<template>
  <div class="card">
    <div class="card-header pb-0 d-flex align-items-center">
      <h6 class="mb-0 domain-title">購入済みのライセンス一覧</h6>
      <a v-if="!isAdmin" class="btn btn-outline-primary btn-sm mb-0" @click="addNewDomain">
        ドメイン追加（ライセンス購入）
      </a>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">ドメイン</th>
              <th
              v-if="isAdmin"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >Key</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >購入日付</th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody v-if="domains && domains.length > 0">
            <tr v-for="domain in domains" :key="domain.key">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ domain.domain }}</h6>
                  </div>
                </div>
              </td>
              <td v-if="isAdmin">
                <p class="text-xs font-weight-bold mb-0">{{ domain.key }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ domain.payedDate }}</p>
              </td>
              <td class="align-middle text-right">
                <router-link
                  :to="`/domains/${domain.id}`"
                  class="btn text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Delete domain"
                >詳細・編集</router-link>
                <a
                  v-if="domain.status !== 1 && isAdmin"
                  @click="agreeDomain(domain.id)"
                  class="btn text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Delete domain"
                >承認する</a>
                <a
                  v-if="isAdmin"
                  @click="deleteDomain(domain.id)"
                  class="btn text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Delete domain"
                >削除する</a>
                <a
                  v-if="domain.status === 1 && isAdmin"
                  @click="downloadKey(domain.key)"
                  class="btn text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Delete domain"
                >Keyをダウンロードする</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import Swal from 'sweetalert2'
import { auth, db } from "../../firebaseInit";
import {addDoc, collection, doc, setDoc} from 'firebase/firestore'
import { getStorage, ref as storageLib, getDownloadURL,  uploadBytes } from "firebase/storage";
import md5 from "md5";
import { useRouter } from 'vue-router';
export default {
  name: "domains-table",
  data () {
    return {
      isAdmin: false
    }
  },
  setup: () => {
    const store = useStore()
    const router = useRouter()
    const error = ref(null)
    let showModal = false;
    const fetchDomains = async () => {
      try {
        await store.dispatch('getDomains')
      } catch (err) {
        error.value = err.message
      }
    }

    const addNewDomain = async () => {
      const { value: domain} = await Swal.fire({
        title: 'ドメインを追加する（ライセンス購入）',
        input: 'text',
        inputLabel: 'ドメイン名（予め担当者と購入処理は行った上で追加ください）',
        inputPlaceholder: 'https://my-domain.com',
        showCancelButton: true,
        cancelButtonText: 'キャンセル',
        inputValidator: (value) => {
          if (!value) {
            return 'ドメイン名を入力してください。'
          }
        }
      })

      if (domain) {
        const serversCollection = collection(db, "servers");
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const hash = md5(domain);
        const newDomain = await addDoc(serversCollection, {
          domain: domain,
          key: hash,
          dateTime: timestamp,
          userId: auth.currentUser.uid,
          status: 0
        });

        var docBlob = new Blob([hash]);

        const storage = getStorage();
        const storageRef = storageLib(storage, hash);

        // 'file' comes from the Blob or File API
        await uploadBytes(storageRef, docBlob).then((snapshot) => {
          console.log(snapshot)
          console.log('Uploaded a blob or file!');
        });
        await fetchDomains()
        router.push(`/domains/${newDomain.id}`)
      }
    }

    const deleteDomain = async (key) => {
      await Swal.fire({
        title: 'ドメインを削除しますか?',
        showDenyButton: false,
        showCancelButton: true,
      }).then(function (e) {
        if (!e.isDismissed) {
        store.dispatch('deleteDomain', { key: key })
          fetchDomains()
        }
      })
    }

    const downloadKey = (key) => {
      const storage = getStorage();
      getDownloadURL(storageLib(storage, key)).then(url => {
        var link = document.createElement('a');
        link.download = 'download';
        link.href = url;
        link.target = '_blank'
        link.click();
      })
    }

    const fetchUsers = async () => {
      try {
        await store.dispatch('getUsers')
      } catch (err) {
        error.value = err.message
      }
    }
    
    const domains = computed(() => {
      console.log(store.getters.domains)
      return store.getters.domains;
    });

    const user = computed(() => {
      return store.getters.user;
    });

    return {
      fetchUsers, fetchDomains, domains, showModal, addNewDomain, deleteDomain, downloadKey, user
    }
  },
  mounted () {
    this.fetchDomains()
    this.fetchUsers()
    this.isAdmin = this.user && this.user.role === 'admin' ? true : false
  },
  methods: {
    async agreeDomain(key) {
      let self = this
      await Swal.fire({
        title: 'ドメインを承認しますか?',
        showDenyButton: false,
        showCancelButton: true,
      }).then(async function (e) {
        if (!e.isDismissed) {
          const cDomain = self.domains.find(d => d.id === key)
          await setDoc(doc(db, 'servers', key), {
            ...cDomain,
            status: 1
          })
          self.fetchDomains()
          Swal.fire('','承認されました。', 'success')
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  margin-bottom: 0;
}
.domain-title {
  flex: 1;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from, .modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
