import { createStore } from "vuex";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebaseInit";
import { collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, where } from "firebase/firestore"; 
import VuexPersistence from "vuex-persist";
const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  state: {
    auth: {
      isSigned: false,
      data: null
    },
    domains: [],
    users: [],
    notifications: [],
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: "bg-white",
    isRTL: false,
    mcolor: "",
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: "default"
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.auth.isSigned = value;
    },
    SET_USER (state, data) {
      state.auth.data = data;
    },
    SET_DOMAINS (state, data) {
      state.domains = data;
    },
    SET_NOTIFICATIONS (state, data) {
      state.notifications = data;
    },
    SET_USERS (state, data) {
      state.users = data;
    },
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector(".g-sidenav-show");

      if (sidenav_show.classList.contains("g-sidenav-hidden")) {
        sidenav_show.classList.remove("g-sidenav-hidden");
        sidenav_show.classList.add("g-sidenav-pinned");
        state.isPinned = true;
      } else {
        sidenav_show.classList.add("g-sidenav-hidden");
        sidenav_show.classList.remove("g-sidenav-pinned");
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    }
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit("sidebarType", payload);
    },
    async getDomains ({commit, state}) {
      let domains = [];
      if (state.auth.data && state.auth.data.role === 'admin') {
        const serversCollection = collection(db, "servers");
        const querySnapshot = await getDocs(query(serversCollection, orderBy('dateTime')));
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          domains.push({
            ...doc.data(),
            id: doc.id
          })
        });
      } else if (state.auth.data) {
        const serversCollection = collection(db, "servers");
        const querySnapshot = await getDocs(query(serversCollection, where("userId", "==", state.auth.data.uid)), orderBy('dateTime'));
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          domains.push({
            ...doc.data(),
            userId: doc.data().userId,
            id: doc.id
          })
        });
      }
      commit('SET_DOMAINS', domains)
    },
    async getNotifications ({commit}) {
      let notifications = [];
        const serversCollection = collection(db, "notifications");
        const querySnapshot = await getDocs(query(serversCollection, orderBy('dateTime')));
        querySnapshot.forEach((doc) => {
          notifications.push({
            ...doc.data(),
            id: doc.id
          })
        });
      commit('SET_NOTIFICATIONS', notifications)
    },
    async getUsers ({commit, state}) {
      let users = [];
      const serversCollection = collection(db, "users");
      let q;
      if (state.auth.data.role !== 'admin') {
        q = query(query(serversCollection, where("uid", "==", state.auth.data.uid)), orderBy('dateTime'))
      } else {
        q = query(serversCollection, orderBy('dateTime'))
      }
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        users.push({
          ...doc.data(),
          id: doc.id
        })
      });
      commit('SET_USERS', users)
    },
    
    async deleteDomain (context, {key}) {
      const serversCollection = doc(db, "servers", key);
      deleteDoc(serversCollection);
    },
    async logIn (context, { email, password }) {
      try {
        const response = await signInWithEmailAndPassword(auth, email, password)
        if (response) {
          const userDoc = doc(db, "users", response.user.uid);
          const userInfo = await getDoc(userDoc);
          context.commit('SET_USER', { ...response.user, role: userInfo.data().role })
          return true
        } else {
          // throw new Error('login failed')
          return false
        }
      } catch (e) {
        console.error(e)
        return false
      }
    },
    async logout (context) {
      auth.signOut()
      context.commit('SET_USER', {})
      context.commit('SET_LOGGED_IN', false)
    },
    async fetchUser (context, user) {
      context.commit("SET_LOGGED_IN", user !== null);
      if (user) {
        const userDoc = doc(db, "users", user.uid);
        const userInfo = await getDoc(userDoc);
        context.commit("SET_USER", {
          email: userInfo.data().email,
          uid: userInfo.data().uid,
          dateTime: userInfo.data().dateTime,
          name: userInfo.data().name,
          role: userInfo.data().role
        });
      } else {
        context.commit("SET_USER", null);
      }
    }
  },
  getters: {
    user (state) {
      return state.auth.data
    },
    domains (state) {
      return state.domains
    },
    users (state) {
      return state.users
    },
    notifications (state) {
      return state.notifications
    },
  },
  plugins: [
    vuexLocal.plugin
  ]
});
