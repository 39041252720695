<template>
  <div class="card">
    <div class="card-header pb-0 d-flex align-items-center">
      <h6 class="mb-0 domain-title">お知らせ一覧</h6>
      <a v-if="user && user.role === 'admin'" class="btn btn-outline-primary btn-sm mb-0" @click="addNewNotification">
        お知らせ追加
      </a>
    </div>
    <div class="card-body px-0 pt-0 pb-2 px-3">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">日付</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >タイトル</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >お知らせ内容</th>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">動作</th>
            </tr>
          </thead>
          <tbody v-if="notifications && notifications.length > 0">
            <tr v-for="notification in notifications" :key="notification.key">

              <td>
                <p class="text-xs font-weight-bold mb-0">{{ new Date(notification.dateTime).toLocaleDateString() }}</p>
              </td>
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ truncateText(notification.title, 25) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ truncateText(notification.title, 25) }}</p>
              </td>
              <td>
                <router-link
                  :to="`/notifications/${notification.id}`"
                  class="btn text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="詳細"
                >詳細</router-link>
                <a
                  v-if="user.role === 'admin'"
                  @click="deleteNotification(notification.id)"
                  class="btn text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Delete domain"
                >削除する</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import Swal from 'sweetalert2';
import { addDoc, collection, deleteDoc, doc } from '@firebase/firestore';
import { db } from '../../firebaseInit';
import { useRouter } from 'vue-router';
export default {
  name: "notifications-table",
  data () {
    return {
    }
  },
  setup: () => {
    const store = useStore()
    const router = useRouter()
    const error = ref(null)
    let showModal = false;
    const getNotifications = async () => {
      try {
        await store.dispatch('getNotifications')
      } catch (err) {
        error.value = err.message
      }
    }
    const notifications = computed(() => {
      return store.getters.notifications;
    });

    const truncateText = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    }

    const user = computed(() => {
      return store.getters.user;
    });

    const deleteNotification = async (id) => {
      Swal.fire('', '削除しますか？', 'warning')
        .then(async (e) => {
          if (e.isConfirmed) {
            const serversCollection = doc(db, "notifications", id);
            await deleteDoc(serversCollection);
            getNotifications()
          }
      })
    }

    const addNewNotification = async () => {
    var $ = require('jquery')
      const { value: {title, description}} = await Swal.fire({
        title: 'お知らせの追加',
        html:
          '<input type="text" id="title" class="w-100 form-control" placeholder="お知らせタイトル">' +
          '<textarea id="description" class="mt-2 form-control" placeholder="お知らせ内容" />',
        preConfirm: function () {
          return new Promise(function (resolve) {
            resolve({
              title: $('#title').val(),
              description: $('#description').val(),
            })
          })
        },
        showCancelButton: true,
      })

      if (title && description) {
        const serversCollection = collection(db, "notifications");
        const currentDate = new Date();
        const timestamp = currentDate.getTime();
        const newNotification = await addDoc(serversCollection, {
          title: title,
          dateTime: timestamp,
          description: description,
          status: 0
        });
        await getNotifications()
        router.push(`/notifications/${newNotification.id}`)
      }
    }

    return {
      getNotifications, notifications, showModal, user, addNewNotification, deleteNotification, truncateText
    }
  },
  mounted () {
    this.getNotifications()
  }
};
</script>

<style lang="scss" scoped>
.domain-title {
  flex: 1;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from, .modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
