<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
        <div class="card">
          <div class="card-body mt-2 pt-0 pb-2">
            <div class="table-responsive p-0">
              <table class="table align-items-center mb-0">
                <tbody>
                  <tr>
                    <th>
                      法人名 or 個人名
                    </th>
                    <td>
                      <input :disabled="authUser.role !== 'admin'" class="form-control" type="text" v-model="user.name">
                    </td>
                  </tr>
                  <tr>
                    <th>
                      メールアドレス
                    </th>
                    <td>
                      <input class="form-control" type="email" v-model="user.email" disabled>
                    </td>
                  </tr>
                  <tr v-if="authUser.role === 'admin'">
                    <th>
                      権限
                    </th>
                    <td>
                      <select class="form-control" name="" v-model="user.role" id="">
                        <option value="admin">管理者</option>
                        <option value="role">ユーザー</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      ライセンス名義
                    </th>
                    <td>
                      <input :disabled="authUser.role !== 'admin'" class="form-control" type="text" v-model="user.lisence_name">
                    </td>
                  </tr>
                  <tr>
                    <th>
                      担当者名
                    </th>
                    <td>
                      <input class="form-control" type="text" v-model="user.admin_name">
                    </td>
                  </tr>
                  <tr>
                    <th>
                      電話番号
                    </th>
                    <td>
                      <input class="form-control" type="text" v-model="user.phone">
                    </td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
              <button type="button" class="form-control btn btn-primary mt-3" @click="saveUser">保存する</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, getDoc, setDoc } from '@firebase/firestore';
import { db } from '../firebaseInit';
import Swal from 'sweetalert2';
import { useStore } from 'vuex';
import { computed } from '@vue/reactivity';
export default {
  name: "tables",
  data () {
    return {
      user: {},
      uid: this.$router.currentRoute.value.params?.uid
    }
  },
  components: {
  },
  async mounted () {
      // const sUsers = store.getters.users
      // return sUsers[sUsers.findIndex(u => u.id === uid)]
      const serversCollection = doc(db, "users", this.uid);
      const cUser = await getDoc(serversCollection)
      this.user = cUser.data()
      return 
  },
  setup () {
    const store = useStore();
    const authUser = computed(() => {
      return store.getters.user
    })
    return {authUser}
  },
  methods: {
    async saveUser () {
      const serversCollection = doc(db, "users", this.uid);
      try {
        await setDoc(serversCollection, this.user)
        // const self = this
        Swal.fire('', '成功しました。', 'success').then(function () {
          // self.$router.push('/users')
        })
      } catch (e) {
        console.error(e)
      }
    }
  }
};
</script>
