<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          url="/dashboard-default"
          :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          navText="ダッシュボード"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/domains"
          :class="getRoute() === 'domains' ? 'active' : ''"
          navText="保有ライセンス一覧"
        >
          <template v-slot:icon>
            <i
              class="fa fa-list text-primary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="user && user.role === 'admin'"> 
        <sidenav-item
          url="/users"
          :class="getRoute() === 'users' ? 'active' : ''"
          navText="ユーザー一覧"
        >
          <template v-slot:icon>
            <i
              class="fa fa-users text-primary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="user && user.role !== 'admin'"> 
        <sidenav-item
          :url="`/users/${user.uid}`"
          :class="getRoute() === 'domains' ? 'active1' : ''"
          navText="登録情報"
        >
          <template v-slot:icon>
            <i
              class="fa fa-user text-primary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/notifications"
          :class="getRoute() === 'notifications' ? 'active' : ''"
          navText="お知らせ一覧"
        >
          <template v-slot:icon>
            <i
              class="fa fa-bell text-primary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="user && user.role !== 'admin'">
        <sidenav-item
          url="https://www.chatwork.com/mallento_sato"
          navText="新規申し込み一覧"
          :external="true"
        >
          <template v-slot:icon>
            <i
              class="fa fa-address-book text-primary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="user && user.role !== 'admin'">
        <sidenav-item
          url="https://www.chatwork.com/mallento_sato"
          navText="カスタマイズ申し込み"
          :external="true"
        >
          <template v-slot:icon>
            <i
              class="fa fa-address-book text-primary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="user && user.role !== 'admin'">
        <sidenav-item
          url="https://www.chatwork.com/mallento_sato"
          navText="お問い合わせ"
          :external="true"
        >
          <template v-slot:icon>
            <i
              class="ni ni-calendar-grid-58 text-primary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="user && user.role !== 'admin'">
        <sidenav-item
          url="https://mallento.com/faq/"
          navText="よくある質問"
          :external="true"
        >
          <template v-slot:icon>
            <i
              class="ni ni-calendar-grid-58 text-primary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item" v-if="user && user.role !== 'admin'">
        <sidenav-item
          url="https://mallento.com/wp-content/uploads/2020/04/株式会社マレント開発業務等受託約款-兼-同意書.pdf"
          navText="約款・利用規約"
          :external="true"
        >
          <template v-slot:icon>
            <i
              class="ni ni-calendar-grid-58 text-primary text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import SidenavItem from "./SidenavItem.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "MitsuiSSOL",
      controls: "dashboardsExamples",
      isActive: "active"
    };
  },
  computed: {
    user () {
      const store = useStore()
      return store.getters.user;
    }
  },
  components: {
    SidenavItem,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>
