<template>
  <div class="py-4 container-fluid">
    <div class=" row">
      <div class="col-12">
        <div class="card">
          <div class="card-body mt-2 pt-0 pb-2">
            <div class="table-responsive p-0">
              <div class="card text-left">
                <div class="card-body">
                  <h4 class="card-title pb-2">
                    <p class="text-sm mb-2">{{ (new Date(notification.dateTime)).toLocaleDateString() }}</p>
                  </h4>
                  <h3>
                    <input v-if="user.role === 'admin'" type="text" v-model="notification.title" class="form-control">
                    <template v-else>
                      {{notification.title}}
                    </template>
                  </h3>
                  <p class="card-text">
                    <textarea v-if="user.role === 'admin'" class="form-control" name="" v-model="notification.description" id="" cols="30" rows="10"></textarea>
                    <template v-else>
                      {{notification.description}}
                    </template>
                  </p>
                </div>
                <div class="card-footer">
                  <button v-if="user.role === 'admin'" type="button" class="form-control btn btn-primary mt-3" @click="savenotification">保存する</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, getDoc, setDoc } from '@firebase/firestore';
import { db } from '../firebaseInit';
import Swal from 'sweetalert2';
import { useStore } from 'vuex';
import { computed } from '@vue/reactivity';
export default {
  name: "tables",
  data () {
    return {
      notification: {},
      id: this.$router.currentRoute.value.params?.id
    }
  },
  components: {
  },
  async mounted () {
      // const snotifications = store.getters.notifications
      // return snotifications[snotifications.findIndex(u => u.id === uid)]
      const serversCollection = doc(db, "notifications", this.id);
      const cnotification = await getDoc(serversCollection)
      this.notification = cnotification.data()
      return 
  },
  setup () {
    const store = useStore();
    const user = computed(() => {
      return store.getters.user
    })
    const authnotification = computed(() => {
      return store.getters.notification
    })
    return {authnotification, user}
  },
  methods: {
    async savenotification () {
      const serversCollection = doc(db, "notifications", this.uid);
      try {
        await setDoc(serversCollection, this.notification)
        const self = this
        Swal.fire('', '成功しました。', 'success').then(function () {
          self.$router.push('/notifications')
        })
      } catch (e) {
        console.error(e)
      }
    }
  }
};
</script>
