import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import Dashboard from "./argon-dashboard";
import $ from 'jquery'

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(Dashboard);
appInstance.use($);
appInstance.mount("#app");
