import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Domains from "../views/Domains.vue";
import Notifications from "../views/Notifications.vue";
import Users from "../views/Users.vue";
import User from "../views/User.vue";
import NotificationDetail from "../views/NotificationDetail.vue";
import Domain from "../views/Domain.vue";
import Signin from "../views/Signin.vue";
import { auth } from "./../firebaseInit";
// import store from "../store";

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/dashboard-default",
  },
  {
    path: "/dashboard-default",
    name: "ダッシュボード",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users",
    name: "ユーザー一覧",
    component: Users,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: "/users/:uid",
    name: "ユーザー詳細",
    component: User,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/domains",
    name: "ドメイン一覧",
    component: Domains,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/domains/:id",
    name: "ドメイン詳細",
    component: Domain,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/notifications",
    name: "お知らせ一覧",
    component: Notifications,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/notifications/:id",
    name: "お知らせ詳細",
    component: NotificationDetail,
    meta: {
      requiresAuth: true
    }
  },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    auth.onAuthStateChanged(user => {
      if (!user) {
        next({ name: 'Signin' })
      } else {
        next() // go to wherever I'm going
      }
    });
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})


export default router;
