<template>
  <div class="card">
    <div class="card-header pb-0 d-flex align-items-center">
      <h6 class="mb-0 domain-title">ユーザー一覧</h6>
      <a v-if="user && user.role === 'admin'" class="btn btn-outline-primary btn-sm mb-0" @click="addNewUser">
        ユーザー追加
      </a>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">名前</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >UID</th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >権限</th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody v-if="users && users.length > 0">
            <tr v-for="user in users" :key="user.uid">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ user.name }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ user.uid }}</p>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ user.role === 'admin' ? '管理者' : 'ユーザー' }}</p>
              </td>
              <td class="align-middle text-right">
                <router-link
                  :to="{path: `/users/${user.uid}`}"
                  class="btn text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Delete domain"
                >詳細</router-link>
                <a
                  @click="deleteUser(user.uid)"
                  class="btn text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Delete domain"
                >削除する</a>
                <a
                  @click="changeRole(user)"
                  class="btn text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Delete domain"
                >
              {{ user.role === 'admin' ? 'ユーザーにする': '管理者にする'}}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import Swal from 'sweetalert2'
import {
  db,
  // auth
} from "../../firebaseInit";
import {
  createUserWithEmailAndPassword, getAuth,
  // deleteUser as fDeleteUser,
  // reauthenticateWithCredential, EmailAuthProvider
} from '@firebase/auth';
import {
  deleteDoc,
  doc, setDoc
} from '@firebase/firestore';
export default {
  name: "users-table",
  data () {
    return {
    }
  },
  setup: () => {
    const store = useStore()
    const error = ref(null)
    let showModal = false;
    const fetchUsers = async () => {
      try {
        await store.dispatch('getUsers')
      } catch (err) {
        error.value = err.message
      }
    }
    var $ = require('jquery')
    const addNewUser = async () => {
      const { value: {name, email, password}} = await Swal.fire({
        title: 'ユーザーの追加',
        html:
          '<input type="text" id="name" class="swal2-input" placeholder="お名前">' +
          '<input type="email" id="email" class="swal2-input" placeholder="Email">' +
          '<input type="password" id="password" class="swal2-input" placeholder="password">',
        preConfirm: function () {
          return new Promise(function (resolve) {
            resolve({
              name: $('#name').val(),
              email: $('#email').val(),
              password: $('#password').val()
            })
          })
        },
        showCancelButton: true,
      })

      if (email&& password) {
        const auth = getAuth();
        createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            // Signed in 
            const user = userCredential.user;

            const serversCollection = doc(db, "users", user.uid);
            const currentDate = new Date();
            const timestamp = currentDate.getTime();
            await setDoc(serversCollection, {
              name: name,
              email: email,
              dateTime: timestamp,
              uid: user.uid,
              role: 'user'
            });
            // ...

            fetchUsers()
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode)
            console.log(errorMessage)
            fetchUsers()
            // ..
          });
      }
    }

    const deleteUser = async (uid) => {
      await Swal.fire({
        title: 'ユーザーを削除しますか?',
        showDenyButton: false,
        showCancelButton: true,
      }).then(async function (e) {
        if (e.isConfirmed) {
          const { value: password} = await Swal.fire({
            title: 'パスワードを確認',
            input: 'password',
            inputLabel: 'パスワード',
            inputPlaceholder: '',
            showCancelButton: true,
            inputValidator: (value) => {
              if (!value) {
                return 'を入力してください。'
              }
            }
          })
          if (password) {
            // const cuser = auth.currentUser;
            // const credential = EmailAuthProvider.credential(cuser.email, password);            
            // reauthenticateWithCredential(cuser, credential).then(async function (e) {
            //   console.log(e)
            //   console.log(uid)
            //   await fDeleteUser(uid)
            //   fetchUsers()
            // }).catch(function (e) {
            //   console.log(e)
            // })
            const serversCollection = doc(db, "users", uid);
            await deleteDoc(serversCollection);
          }
        }
      })
    }

    const changeRole = async (user) => {
      await Swal.fire({
        title: 'ユーザーの権限を変更しましょうか？',
        showDenyButton: false,
        showCancelButton: true,
      }).then(async function (e) {
        if (e.isConfirmed) {
          const serversCollection = doc(db, "users", user.uid);
          await setDoc(serversCollection, {
            ...user,
            role: user.role === 'admin' ? 'user' : 'admin'
          });
          fetchUsers()
        }
      })
    }

    const users = computed(() => {
      return store.getters.users;
    });

    const user = computed(() => {
      return store.getters.user;
    });

    return {
      fetchUsers, users, showModal, addNewUser, deleteUser, changeRole, user
    }
  },
  mounted () {
    this.fetchUsers()
  }
};
</script>

<style lang="scss" scoped>
.domain-title {
  flex: 1;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from, .modal-leave-to {
  opacity: 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>
