<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-6">
            <card
              :title="stats.domains.title"
              :value="domains.length"
              :percentage="stats.domains.percentage"
              :iconClass="stats.domains.iconClass"
              :iconBackground="stats.domains.iconBackground"
              :detail="stats.domains.detail"
              :linkTitle="stats.domains.linkTitle"
              :link="stats.domains.link"
              directionReverse
            ></card>
          </div>
          <div v-if="user.role === 'admin'" class="col-lg-6 col-md-6 col-6">
            <card
              :title="stats.clients.title"
              :value="stats.clients.value"
              :percentage="stats.clients.percentage"
              :iconClass="stats.clients.iconClass"
              :iconBackground="stats.clients.iconBackground"
              :percentageColor="stats.clients.percentageColor"
              :detail="stats.clients.detail"
              directionReverse
            ></card>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-lg-12 mb-lg-0 mb-4">
            <div class="card">
              <div class="p-3 pb-0 card-header">
                <div class="d-flex justify-content-between">
                  <h6 class="mb-2">お知らせ一覧</h6>
                </div>
              </div>
              <div class="table-responsive">
                <table class="table align-items-center">
                  <tbody>
                    <tr v-for="(notification) in notifications" :key="notification.title">
                      <td class="w-30">
                        <div class="px-2 py-1 d-flex align-items-center">
                          <div class="ms-4">
                            <p class="mb-0 text-xs font-weight-bold"></p>
                            <h6 class="mb-0 text-sm">{{ (new Date(notification.dateTime)).toLocaleDateString() }}</h6>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="">
                          <router-link :to="`/notifications/${notification.id}`" class="mb-0 text-sm title_text text-align-left">{{ truncateText(notification.title, 25) }}</router-link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { auth } from '../firebaseInit'
import { computed } from 'vue';
import Card from "@/standalone/Cards/Card.vue";

export default {
  name: "dashboard-default",
  data() {
    return {
      stats: {
        domains: {
          title: "登録済みのドメイン数",
          value: "50",
          iconClass: "ni ni-shop",
          detail: "",
          iconBackground: "bg-gradient-primary",
          link: "/domains",
          linkTitle: "もっと見る"
        },
        clients: {
          title: "登録済みのユーザー数",
          value: this.users.length,
          iconClass: "fa fa-user",
          iconBackground: "bg-gradient-success",
          detail: "",
          link: "/domains",
        },
      },
    };
  },
  setup: () => {
    const store = useStore()
    const router = useRouter()
    const user = computed(() => {
      return store.getters.user;
    });

    const truncateText = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    }

    const domains = computed(() => {
      return store.getters.domains;
    });
    const notifications = computed(() => {
      return store.getters.notifications;
    });
    auth.onAuthStateChanged(user => {
      if (!user) {
        router.push('/signin')
      }
      store.dispatch("fetchUser", user);
      store.dispatch("getDomains");
      store.dispatch("getNotifications");
    });

    const signOut = async () => {
          await store.dispatch('logOut')
          router.push('/')
    }

    const users = computed(() => {
      return store.getters.users;
    });

    return {user,signOut,domains, notifications, users, truncateText}
  },
  components: {
    Card,
  },
};
</script>

<style lang="scss" scoped>
</style>
